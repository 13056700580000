import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          this site is under construction !!
          <br /> in the meantime, you can check out my old <br />
          portfolio,{" "}
          <a href="https://old.chiu.fyi" target="_blank" rel="noreferrer">
            old.chiu.fyi
          </a>{" "}
          (last updated 2018)
        </p>
      </header>
    </div>
  );
}

export default App;
